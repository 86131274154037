import React, { Component } from "react";

class Navbar extends Component {
	render() {
		return (
			<div className='app-header header--transparent sticker' id='main-menu'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-3 col-sm-5 col-5'>
							<div className='logo'>
								<a href={`${process.env.PUBLIC_URL}/`}>
									<img
										className='logo-1'
										src={require("../assets/images/logo/logo.png")}
										alt='app landing'
									/>
								</a>
							</div>
						</div>
						<div className='col-lg-9 d-none d-lg-block'>
							<div className='mainmenu-wrapper d-flex justify-content-between'>
								<nav>
									<ul className='main-menu'>
										<li className='active'>
											<a href='#home'>Home</a>
										</li>
										<li>
											<a
												href='https://about.biteexpress.ng'
												target='_blank'
												rel='noopener noreferrer'>
												About
											</a>
										</li>
										<li>
											<a href='#topvendors'>Vendors</a>
										</li>
										<li>
											<a href='#features'>Features</a>
										</li>
										<li>
											<a href='#download'>Download</a>
										</li>
										<li>
											<a href='#screenshots'>How It Works</a>
										</li>
									</ul>
								</nav>
								<button
									className='button-default button-olive'
									type='button'
									onClick={() =>
										window.open("https://linktr.ee/biteexpress", "_blank")
									}>
									GET STARTED
								</button>
							</div>
						</div>

						<div className='col-sm-7 col-7 d-block d-lg-none'>
							<div className='mobile-menu'>
								<nav>
									<ul>
										<li className='active'>
											<a href='#home'>Home</a>
										</li>
										<li>
											<a
												href='https://about.biteexpress.ng'
												target='_blank'
												rel='noopener noreferrer'>
												About
											</a>
										</li>
										<li>
											<a href='#topvendors'>Vendors</a>
										</li>
										<li>
											<a href='#features'>Features</a>
										</li>
										<li>
											<a href='#download'>Download</a>
										</li>
										<li>
											<a href='#screenshots'>How It Works</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Navbar;
