import React, { Component } from "react";

class LetsDo extends Component {
	render() {
		let data = [
			{
				image: "Become_a_Driver.png",
				title: "Become a Delivery Man",
				link: "http://drivers.biteexpress.ng/",
				desc: "As a delivery driver, you'll make reliable money—working anytime, anywhere.",
				buttonText: "Start Earning",
			},
			{
				image: "Become_a_Vendor.png",
				title: "Become a Partner",
				link: "https://vendors.biteexpress.ng/",
				desc: "Grow your business and reach new customers by partnering with us.",
				buttonText: "Sign up your store",
			},
			{
				image: "Become_a_Agent.png",
				title: "Careers",
				link: "http://jobs.biteexpress.ng",
				desc: "Explore exciting careers and join our thriving team today",
				buttonText: "Explore Careers",
			},
		];
		let DataList = data.map((val, i) => {
			return (
				<div className='col-lg-4 col-md-6 col-12' key={i}>
					<div className='blog'>
						<div className='thumb'>
							<a href={val.link}>
								<img
									className='w-100'
									src={require(`../assets/images/blog/${val.image}`)}
									alt='Blog thumb'
								/>
							</a>
						</div>
						<div className='inner'>
							<h3 className='title'>
								<a href={val.link}>{val.title}</a>
							</h3>
							<p className='desc mt--10 mb--30'>{val.desc}</p>
							<div className='blog-btn'>
								<a
									className='button-link'
									href={val.link}
									target='_blank'
									rel='noopener noreferrer'>
									<strong>{val.buttonText}</strong> &nbsp;
									<i className='zmdi zmdi-arrow-right'></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			);
		});
		return (
			<div className='blog-area pt--80 pt_sm--40'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center mb--80'>
								<h2>
									Lets Do It <span className='theme-color'> Together</span>
								</h2>
								<img
									className=''
									src={require("../assets/images/app/title-icon.png")}
									alt='App Landing'
								/>
								<p>
									Join BiteExpress: Redefine delivery, embrace challenges, and
									celebrate success. Together, we achieve greatness!
								</p>
							</div>
						</div>
					</div>
					<div className='row'>{DataList}</div>
				</div>
			</div>
		);
	}
}

export default LetsDo;
