import React, { Component } from "react";

class HeroOlive extends Component {
	render() {
		return (
			<div
				className={`slider-area ${this.props.horizontal} ${this.props.bgshape}`}
				id='home'
				style={{
					backgroundImage: `url(${require("../assets/images/app/background-image.jpg")})`,
					backgroundSize: "cover",
					position: "relative",
					zIndex: 1,
				}}>
				<div
					className='overlay'
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						zIndex: -1,
					}}></div>
				<div className='container h-100'>
					<div className='row'>
						<div className='col-lg-7 h-100'>
							<div className='banner-text'>
								<div className='banner-table-cell'>
									<h1>
										Tasty chow, <br />{" "}
										<span className='theme-color'>delivered</span> with extra
										swag!
									</h1>
									<p>
										Food, drinks, groceries and more delivered to your
										doors-step.
										<br />
										Download and get started on
									</p>
									<div className='download-buttons pb-5 '>
										<button
											onClick={() =>
												window.open(
													"https://apps.apple.com/app/biteexpress/id6466211766",
													"_blank"
												)
											}
											type='submit'
											className='download-btn '>
											<i className='zmdi zmdi-apple'></i>
											<span>
												Available on the{" "}
												<span className='large-text'>App Store</span>{" "}
											</span>
										</button>
										<button
											onClick={() =>
												window.open(
													"https://play.google.com/store/apps/details?id=com.biteexpress.biteexpress",
													"_blank"
												)
											}
											type='submit'
											className='download-btn '>
											<i className='zmdi zmdi-google-play'></i>
											<span>
												Available on the{" "}
												<span className='large-text'>Play Store</span>{" "}
											</span>
										</button>
									</div>
								</div>
							</div>

							<div className='banner-apps'>
								{/* Start Sinhle app */}
								<div className='single-app'>
									<div className='single-app-table-cell'>
										<i className='zmdi zmdi-apple'></i>
										<h4>ios</h4>
										<h3>1.3k</h3>
									</div>
								</div>

								{/* Start Sinhle app */}
								<div className='single-app'>
									<div className='single-app-table-cell'>
										<i className='zmdi zmdi-android' />
										<h4>Android</h4>
										<h3>3.2k</h3>
									</div>
								</div>

								{/* Start Single app */}
								<div className='single-app'>
									<div className='single-app-table-cell'>
										<i className='zmdi zmdi-cloud-download'></i>
										<h4>Download</h4>
										<h3>2.5k</h3>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-5'>
							<div className='banner-product-image text-right'>
								<img
									className='image-1'
									src={require("../assets/images/app/banner-mobile.png")}
									alt='App Landing'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HeroOlive;
