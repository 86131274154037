import React from "react";
import ScrollCarousel from "scroll-carousel-react";

const Screenshot = () => {
	return (
		<div className='screenshot-area pt--80 pb--20 ' id='screenshots'>
			<ScrollCarousel
				autoplay
				autoplaySpeed={8}
				speed={7}
				onReady={() => console.log("I am ready")}
				className='screenshot-carousel' // Add a class for custom styling
			>
				{/* Replace these div elements with your screenshot images */}
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-01.png")}
						alt='App Screenshot 1'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-02.png")}
						alt='App Screenshot 2'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-03.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-04.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-05.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-06.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-07.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-08.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-09.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				<div className='screenshot'>
					<img
						src={require("../assets/images/app/screenshot-10.png")}
						alt='App Screenshot 3'
						className='image'
					/>
				</div>
				{/* Add more screenshot images as needed */}
			</ScrollCarousel>
		</div>
	);
};

export default Screenshot;
