import React, { Component } from "react";

class Download extends Component {
	render() {
		return (
			<div
				className={`download-area pt--80 pt_sm--40 ${this.props.horizontal}`}
				id='download'>
				<div className='bg-overlay' />
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-5 offset-lg-1 mt--40'>
							<div className='about-thumbnail mr--35'>
								<img
									className=''
									src={require("../assets/images/app/mobile.png")}
									alt='App Landing'
								/>
							</div>
						</div>
						<div className='col-lg-6 mt--40'>
							<div className='about-content'>
								<h2 className='title text-start'>
									Connecting our users with our intuitive iOS & Android apps.{" "}
								</h2>
								<h3 className='theme-color'>
									Download from the App Store or Google Play.
								</h3>

								<p>
									Enjoy the flavours of local Nigerian dishes with our wide
									selection of vendors — be it your favorite local spot or
									hidden joints.
								</p>

								<p>
									With our user-friendly applications, you can access a world of
									convenience and order your favorite dishes or explore new
									vendors nearby. Enjoy fast and reliable deliveries, be it
									groceries, pharmaceuticals, or unique finds from various shops
									and florists, all within easy reach. Place your order and
									receive it swiftly and conveniently, enhancing your lifestyle
									with BiteExpress.
								</p>
								<div
									className='about-buttons'
									style={{ display: "flex", justifyContent: "start" }}>
									{/* <a
										href='https://linktr.ee/biteexpress'
										style={{ margin: "0 5px" }}
										target='_blank'
										rel='noreferrer'>
										<img
											src={require("../assets/images/app/app-store.png")}
											alt='Download'
											style={{ width: "200px", height: "50px" }}
										/>
									</a>
									<a
										href='https://linktr.ee/biteexpress'
										style={{ margin: "0 5px" }}
										target='_blank'
										rel='noreferrer'>
										<img
											src={require("../assets/images/app/play-store.png")}
											alt='Order More'
											style={{ width: "200px", height: "50px" }}
										/>
									</a> */}

									<a
										href='https://linktr.ee/biteexpress'
										target='_blank'
										rel='noopener noreferrer'>
										<button
											type='button'
											className='button-default button-olive button-border'>
											<i class='zmdi zmdi-smartphone-download'></i>&nbsp;
											Download
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Download;
