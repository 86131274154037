import React, { Component, Fragment } from "react";

import Download from "../components/Download";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import HeroOlive from "../components/HeroOlive";
import LetsDo from "../components/LetsDo";
import Navbar from "../components/Navbar";
import OurCities from "../components/OurCities";
import Screenshot from "../components/Screenshot";
import Service from "../components/Service";
import TopVendors from "../components/TopVendors";
import WhyChoose from "../components/WhyChoose";

class HomeOlive extends Component {
	render() {
		return (
			<Fragment>
				{/* Header Navbar */}
				<Navbar />

				{/* Slider */}
				<HeroOlive bgshape='bg-shape' horizontal='' />

				{/* About */}
				<TopVendors horizontalabout='vertical-about' />

				{/* Cities */}
				<OurCities />

				{/*Service */}
				<Service horizontal='vertical-service' />

				{/*Feature */}
				<Feature horizontalfeature='vertical-feature' />

				{/* WhyChoose */}
				<WhyChoose horizontalpricing='vertical-pricing' />

				{/* LetsDo */}
				<LetsDo />

				{/* Download */}
				<Download horizontal={false} />

				{/* Screenshot */}
				<Screenshot />

				{/* Footer */}
				<Footer horizontal='vertical-footer' />
			</Fragment>
		);
	}
}

export default HomeOlive;
