import React, { Component } from "react";

class WhyChoose extends Component {
	render() {
		return (
			<div
				className={`pricing-table-area pt--80 pt_sm--40 ${this.props.horizontalpricing}`}
				id='pricing'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center mb--80'>
								<h2>
									Why Choose
									<span className='theme-color'> BiteExpress?</span>
								</h2>
								<img
									className=''
									src={require("../assets/images/app/title-icon.png")}
									alt='App Landing'
								/>
								<p>
									BiteExpress prides itself on being the fastest growing, most
									efficient and reliable food and grocery delivery
									service in Nigeria.
								</p>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='column-content text-center'>
								<img
									src={require("../assets/images/app/how-works-2.png")}
									alt='Column 4'
									style={{ maxWidth: "50%" }}
								/>
								<h3 className='title'>Your top local vendors</h3>
								<p className='desc mt--10 mb--30'>
									Discover an array of delightful vendors, offering your
									favorite dishes or tempting you to try something new, all
									within easy reach!
								</p>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='column-content text-center'>
								<img
									src={require("../assets/images/app/how-works-1.png")}
									alt='Column 4'
									style={{ maxWidth: "50%" }}
								/>
								<h3 className='title'>Speedy Deliveries</h3>
								<p className='desc mt--10 mb--30'>
									With our lightning-fast delivery service, your order will
									arrive at your doorstep in a matter of minutes, ensuring you
									won't have to wait long for anything you desire.
								</p>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='column-content text-center'>
								<img
									src={require("../assets/images/app/how-works-3.png")}
									alt='Column 4'
									style={{ maxWidth: "50%" }}
								/>
								<h3 className='title'>Groceries and beyond</h3>
								<p className='desc mt--10 mb--30'>
									Find everyday essentials, groceries, pharmaceuticals, and
									unique finds from various shops and florists. Place your order
									for swift and convenient delivery in your city.
								</p>
							</div>
						</div>
					</div>

					<div className='row '>
						<div className='col-lg-12 text-center mt-5'>
							<button
								onClick={() =>
									window.open("https://web.biteexpress.ng/", "_blank")
								}
								className='button-default button-border'
								style={{
									padding: "15px 25px",
									fontFamily: "Raleway",
									fontSize: "15px",
									fontWeight: 800,
									lineHeight: "30px",
								}}>
								DISCOVER NEARBY VENDORS &nbsp;{" "}
								<i className='zmdi zmdi-arrow-right'></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhyChoose;
