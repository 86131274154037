import React, { Component } from "react";

class Feature extends Component {
	render() {
		let data = [
			{
				icon: "zmdi zmdi-account-circle",
				title: "User App",
				desc: "Explore a seamless and delightful dining experience with our User App. Order your favorite dishes from a wide range of vendors or discover new ones nearby. Enjoy fast, reliable deliveries and the convenience of ordering groceries, pharmaceuticals, and more, all at your fingertips.",
			},

			{
				icon: "zmdi zmdi-store",
				title: "Vendor App",
				desc: "Grow your business and reach new customers with our Vendor App. Easily manage orders, track deliveries, and showcase your offerings to a larger audience. Join BiteExpress as a partner and enhance your sales and customer base effortlessly.",
			},

			{
				icon: "zmdi zmdi-bike",
				title: "Driver App",
				desc: "Become a part of our dynamic team with our Driver App. As a delivery driver, you'll have the flexibility to earn reliable income while working anytime and anywhere. Join us to embark on an exciting journey, delivering joy to our customers across the city.",
			},

			{
				icon: "zmdi zmdi-account-add",
				title: "Agents App",
				desc: "Explore exciting career opportunities with our Agents App. Join our thriving team and contribute to the success of BiteExpress. Enjoy a rewarding career in the fast-paced world of delivery services and be a part of our growth story.",
			},
		];

		let DataList = data.map((val, i) => {
			return (
				/* Start Single Feature */
				<div className='feature' key={i}>
					<div className='feature-icon'>
						<i className={`${val.icon}`} />
					</div>
					<div className='content'>
						<h4 className='title'>{val.title}</h4>
						<p className='desc'>{val.desc}</p>
					</div>
				</div>
				/* End Single Feature */
			);
		});

		return (
			<div
				className={`feature-area feature-bg-image pt--80 pt_sm--40 ${this.props.horizontalfeature}`}
				id='features'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center mb--40'>
								<h2>
									THE BiteExpress
									<span className='theme-color'> ECOSYSTEM</span>
								</h2>
								<img
									className=''
									src={require("../assets/images/app/title-icon.png")}
									alt='App Landing'
								/>
								<p>
									BiteExpress welcomes individuals of diverse backgrounds,
									offering earning opportunities for vendors, freelancers,
									drivers, agents, marketers, and tech professionals to thrive
									in the platform.
								</p>
							</div>
						</div>
					</div>
					<div className='row mt--30'>
						<div className='col-lg-7 offset-lg-5'>
							<div className='feature-list'>{DataList}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Feature;
