// App.js

import { BrowserRouter, Route, Routes } from "react-router-dom"; // Change 'Switch' to 'Routes'
import React, { useState } from "react";

import CookieConsent from "react-cookie-consent";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import HomeOlive from "./pages/HomeOlive";

const App = () => {
	const [showCookiePolicy, setShowCookiePolicy] = useState(false);

	return (
		<div className='App'>
			<BrowserRouter basename='/'>
				<Routes>
					{" "}
					{/* Change 'Switch' to 'Routes' */}
					<Route
						exact
						path={`${process.env.PUBLIC_URL}/`}
						element={<HomeOlive />} // 'component' prop is replaced with 'element' prop
					/>
				</Routes>{" "}
				{/* Change 'Switch' to 'Routes' */}
			</BrowserRouter>

			<CookieConsent
				location='bottom'
				buttonText='Accept All'
				cookieName='cookie-consent'
				expires={365}
				onAccept={() => setShowCookiePolicy(false)}
				declineButtonText='Decline'
				enableDeclineButton
				style={{
					color: "white",
					padding: "5px",
					position: "fixed",
					justifyContent: "center",
					backgroundColor: "#DE1600",
				}}
				buttonStyle={{
					background: "#fff",
					color: "#DE1600",
					padding: "15px 40px 15px 40px",
					fontSize: "1.2em",
					margin: "10px",
					borderRadius: "5px",
				}}
				declineButtonStyle={{
					background: "#fff",
					color: "#DE1600",
					padding: "15px 40px 15px 40px",
					fontSize: "1.2em",
					margin: "10px",
					borderRadius: "5px",
				}}>
				<div>
					We use cookies to enhance your browsing experience, serve personalized
					ads or content, and analyze our traffic. By clicking "Accept All", you
					consent to our use of cookies.
					{/* This website uses cookies to ensure you get the best experience on our
                    website. */}
					<button
						onClick={() => setShowCookiePolicy(true)}
						style={{
							fontSize: "1.2em",
							color: "white",
							textDecoration: "underline",
							border: "none",
							cursor: "pointer",
						}}>
						Learn more
					</button>
				</div>
			</CookieConsent>

			{showCookiePolicy && (
				<CookiePolicyPage onClose={() => setShowCookiePolicy(false)} />
			)}
		</div>
	);
};

export default App;
