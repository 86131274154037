// CookiePolicyPage.jsx

import "./CookiePolicyPage.css";

import React from "react";

const CookiePolicyPage = ({ onClose }) => {
	return (
		<div className='cookie-policy-modal'>
			<div className='cookie-policy-content'>
				<span className='close-button' onClick={onClose}>
					&times;
				</span>
				<h3 className='modal-header'>Cookie Policy</h3>

				<p>
					Welcome to BiteExpress! BiteExpress is a Nigerian-based online food
					and grocery delivery platform that enables customers to order products
					from a variety of restaurants and vendors and have them conveniently
					delivered to their doorstep through our network of independent
					delivery drivers.
				</p>

				<p>
					This Cookie Policy explains how we use cookies and similar
					technologies on our website to provide you with the best possible user
					experience and to analyze website traffic. By using our website, you
					consent to the use of cookies as described in this policy.
				</p>

				<h4>What are Cookies?</h4>

				<p>
					Cookies are small text files that are placed on your device (computer,
					tablet, smartphone) when you visit a website. They are widely used to
					make websites work efficiently, provide a personalized user
					experience, and analyze website traffic and usage.
				</p>

				<h4>How We Use Cookies</h4>

				<p>At BiteExpress, we use cookies for various purposes, including:</p>

				<ul>
					<li>
						<strong>Essential Cookies:</strong> These cookies are necessary for
						the basic functioning of our website. They enable you to navigate
						the website and use its features.
					</li>
					<li>
						<strong>Performance and Analytics Cookies:</strong> These cookies
						help us understand how visitors interact with our website, providing
						valuable insights into areas for improvement and optimizing the user
						experience.
					</li>
					<li>
						<strong>Functionality Cookies:</strong> These cookies allow the
						website to remember choices you make (such as language preferences)
						and provide enhanced, more personalized features.
					</li>
				</ul>

				<h4>Your Choices</h4>

				<p>You can control and manage cookies in several ways:</p>

				<ul>
					<li>
						<strong>Browser Settings:</strong> Most web browsers allow you to
						control cookies through your browser settings. You can configure
						your browser to block or delete cookies or notify you when a cookie
						is set. Please note that if you disable cookies, some parts of our
						website may not function properly.
					</li>
					<li>
						<strong>Third-Party Tools:</strong> You can opt-out of certain
						third-party cookies using tools provided by those third parties.
					</li>
				</ul>

				<h4>Contact Us</h4>

				<p>
					If you have any questions or concerns about our Cookie Policy, please
					feel free to contact us at{" "}
					<a href='mailto:contact@biteexpress.com'>contact@biteexpress.com</a>.
				</p>

				<p>
					Thank you for choosing BiteExpress! We appreciate your trust and
					strive to provide you with an exceptional online experience.
				</p>
			</div>
		</div>
	);
};

export default CookiePolicyPage;
