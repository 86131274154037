import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<div>
				<div className={`footer-area ${this.props.horizontal}`} id='support'>
					<div className='footer-bg' />
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='section-title text-center mb--40 '>
									<h2 className='text-white' style={{ marginTop: "45px" }}>
										Get In{" "}
										<span className='theme-color' style={{ color: "black" }}>
											Touch
										</span>
									</h2>

									<img
										className=''
										src={require("../assets/images/app/title-icon-4.png")}
										alt='App Landing'
									/>
									<p className='text-white'>
										We are excited to connect with you and address any questions
										or inquiries you may have. Our dedicated team is here to
										provide you with the best possible support and solutions.
									</p>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-5 col-xl-4 offset-xl-1'>
								<div className='contact-inner'>
									<h3 className='title'>Say Hello!</h3>
									<form className='contact-form' action='/'>
										<div className='input-box'>
											<input type='text' placeholder='Name' />
										</div>

										<div className='input-box'>
											<input type='text' placeholder='Email' />
										</div>

										<div className='input-box'>
											<textarea placeholder='Message'></textarea>
										</div>

										<div className='input-box'>
											<button className='submite-button' type='submit'>
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
							<div className='col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40'>
								<div className='contact-inner'>
									<h3 className='title'>Contact Us</h3>
									<div className='conatct-info'>
										<div className='single-contact-info'>
											<div className='contact-icon'>
												<i className='zmdi zmdi-home'></i>
											</div>
											<div className='contact-text'>
												<span>
													BiteExpress Software
													<br /> Technology Solutions Ltd.
												</span>
											</div>
										</div>
										<div className='single-contact-info'>
											<div className='contact-icon'>
												<i className='zmdi zmdi-phone'></i>
											</div>
											<div className='contact-text'>
												<span>
													+234 (0) 912 305 1662
													<br />
													+234 (0) 905 511 1373
												</span>
											</div>
										</div>
										<div className='single-contact-info'>
											<div className='contact-icon'>
												<i className='zmdi zmdi-headset-mic'></i>
											</div>
											<div className='contact-text'>
												<span>
													info@biteexpress.ng
													<br />
													https://support.biteexpress.ng
												</span>
											</div>
										</div>
										<div className='single-contact-info'>
											<div className='contact-icon'>
												<i className='zmdi zmdi-pin'></i>
											</div>
											<div className='contact-text'>
												<span>
													D12, D-Plaza, Sokoto Road,
													<br /> Zaria, Kaduna State Nigeria.
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
								<div className='newsletter text-center'>
									<h3 className='text-white title'>
										SUBSCRIBE FOR OUR NEWSLETTER
									</h3>
									<p className='text-white'>
										Stay informed about the latest updates, exclusive offers,
										and exciting promotions! <br />
										Don't miss out - Join our community today.
									</p>
									<form action='/' method='post' id='newsletter'>
										<div className='newsletter-content'>
											<input
												type='text'
												name='email'
												placeholder='Enter your Email address'
											/>
											<button type='submit' className='button'>
												<span>Subscribe</span>
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-8 offset-lg-2 col-xl-4 offset-xl-4'>
								<div className='footer-links text-center'>
									<a href='https://web.facebook.com/biteexpressapp'>
										<i className='zmdi zmdi-facebook'></i>
									</a>
									<a href='https://twitter.com/biteexpress'>
										<i className='zmdi zmdi-twitter'></i>
									</a>
									<a href='https://instagram.com/biteexpressng'>
										<i className='zmdi zmdi-instagram'></i>
									</a>
									<a href='https://www.linkedin.com/showcase/biteexpress/'>
										<i className='zmdi zmdi-linkedin'></i>
									</a>
									<a href='https://www.youtube.com/@BiteExpressApp'>
										<i className='zmdi zmdi-youtube'></i>
									</a>
								</div>
							</div>
							<div
								className='footer-text text-center'
								style={{ whiteSpace: "wrap" }}>
								<span>
									Copyright © {new Date().getFullYear()} BiteExpress Software
									Technology Solutions Ltd, All rights reserved. Powered by{" "}
									<a href='https://phoenixitng.com/'>
										Phoenix Information Technology.
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='tap-top'>
					<div>
						<i className='zmdi zmdi-long-arrow-up'></i>
					</div>
				</div>
			</div>
		);
	}
}
export default Footer;
