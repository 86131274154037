import React, { Component } from "react";

function getAppLink() {
	if (isIOS()) {
		// Use the iOS app link
		return "https://apps.apple.com/us/app/biteexpress/id6466211766";
	} else if (isAndroid()) {
		// Use the Android app link
		return "https://play.google.com/store/apps/details?id=com.biteexpress.biteexpress";
	} else {
		// Use the web link as a fallback
		return "http://web.biteexpress.ng/";
	}
}

function isIOS() {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function isAndroid() {
	return /Android/.test(navigator.userAgent);
}

class TopVendors extends Component {
	render() {
		return (
			<div
				className={`app-about pt--80 pt_sm--40 ${this.props.horizontalabout}`}
				id='topvendors'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center mb--40'>
								<h2>
									TOP VENDORS on{" "}
									<span className='theme-color'>BiteExpress</span>
								</h2>
								<img
									className=''
									src={require("../assets/images/app/title-icon.png")}
									alt='App Landing'
								/>
								<p>
									Indulge in a delightful culinary journey as you discover the
									finest vendors and a whole array of exciting options on
									BiteExpress.
								</p>
							</div>
						</div>
					</div>

					<div className='row mt--40'>
						{/* Each column should be col-lg-3 for desktop and col-6 for mobile */}
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0 '>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=7"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/chicken-republic.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 30px 0",
											whiteSpace: "nowrap",
											borderRadius: "8px",
											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Chicken Republic
									</h4>
								</a>
							</div>
						</div>
						{/* Repeat the above code for each column */}
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=19"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/hopes-place.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 50px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Hope's Place
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=15"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/5g-square.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 50px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										5G Square
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=13"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/mama-bushi.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 50px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Mama Bushi
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=10"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/zaria-suya.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 30px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Zaria Suya Spot
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=20"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/eze-fast-food.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 50px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Eze Fast Food
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a href='/' style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/mama-k.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 70px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Mama K
									</h4>
								</a>
							</div>
						</div>
						<div className='col-lg-3 col-6 mb--20'>
							{/* On mobile, set padding to 0px using p-0 class */}
							<div className='column-content text-center p-0'>
								<a
									href={
										window.innerWidth <= 768
											? getAppLink()
											: "http://web.biteexpress.ng/restaurant?id=8"
									}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: "none", color: "black" }}>
									<img
										src={require("../assets/images/app/zobobossng.png")}
										alt='Column 1'
										style={{ maxWidth: "80%" }}
									/>
									<h4
										style={{
											fontWeight: "bold",
											background: "red",
											color: "white",
											padding: "2px 10px",
											margin: "0 60px",
											whiteSpace: "nowrap",
											borderRadius: "8px",

											...(window.innerWidth <= 768
												? {
														margin: "0 !important",
														whiteSpace: "wrap",
												  }
												: {}),
										}}>
										Zobo Boss
									</h4>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TopVendors;
