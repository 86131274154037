import React, { Component } from "react";

class Service extends Component {
	render() {
		let data = [
			{
				icon: "zmdi zmdi-smartphone-iphone",
				title: "Easy Order",
				desc: "Tap here, tap there, done. Place your order easily and pay with your BitexWallet.",
			},

			{
				icon: "zmdi zmdi-shopping-cart",
				title: "Easy Checkout",
				desc: "Deposit via debit/credit card or eTransfer to your BitexWallet, pay from your wallet balance.",
			},

			{
				icon: "zmdi zmdi-pin",
				title: "Real-time Tracking",
				desc: "Monitor your order’s trip from vendor to doorstep. Get notified on each step of the way.",
			},

			{
				icon: "zmdi zmdi-headset",
				title: "24/7 Support",
				desc: "Customer support services always available via calls, emails & live chat.",
			},
		];
		let DataList = data.map((val, i) => {
			return (
				<div className='col-lg-3 service-column' key={i}>
					<div className='single-service text-center'>
						<div className='service-icon'>
							<i className={`${val.icon}`}></i>
						</div>
						<h4 className='title'>{val.title}</h4>
						<p className='desc'>{val.desc}</p>
					</div>
				</div>
			);
		});

		return (
			<div>
				{/* Start Service Area */}
				<div className={`service-area ${this.props.horizontal}`}>
					<div className='container'>
						<div className='row'>{DataList}</div>
					</div>
				</div>
				{/* End Service Area */}
			</div>
		);
	}
}

export default Service;
