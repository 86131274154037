import React from "react";

class OurCities extends React.Component {
	render() {
		return (
			<div
				className='testimonial-wrapper pt--80 pt_sm--40 text-center '
				id='reviews'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='testimonial-activation'>
								<div className='section-title text-center mb--80'>
									<h2>
										Cities Where
										<span className='theme-color'> We Deliver</span>
									</h2>
									<img
										className=''
										src={require("../assets/images/app/title-icon.png")}
										alt='App Landing'
									/>
									<p>
										Delivering to multiple states, making it convenient for
										customers to access our services nationwide. Enjoy the
										seamless experience of BiteExpress in your state today!
									</p>
								</div>
								{/* <div className='row mb--80'>
									<div className='col-12'>
										<div className='d-flex flex-wrap justify-content-center justify-content-lg-center'>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
														marginRight: "5px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; ZARIA
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
														marginRight: "5px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KADUNA
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
														marginRight: "5px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KANO
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KATSINA
												</button>
											</div>
										</div>
									</div>
								</div> */}

								<div className='row mb--80'>
									<div className='col-12'>
										<div
											className='d-flex flex-wrap justify-content-center'
											style={{ gap: "10px" }}>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; ZARIA
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
														// marginRight:
														// 	window.innerWidth <= 992 ? "0" : "10px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KADUNA
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border'
													style={{
														borderRadius: "50px",
														// marginRight:
														// 	window.innerWidth <= 992 ? "0" : "10px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KANO
												</button>
											</div>
											<div className='col-12 col-lg-auto mb-2'>
												<button
													className='button-default button-olive button-border '
													style={{
														borderRadius: "50px",
														// marginRight:
														// 	window.innerWidth <= 992 ? "0" : "10px",
													}}>
													<i className='zmdi zmdi-pin'></i>&nbsp; KATSINA
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OurCities;
